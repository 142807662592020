
import { Link, graphql } from "gatsby";
import React, {useState} from 'react';
import { useDispatch } from 'react-redux'
import styles from "../../components/innerPage.module.css"
import Layout from '../../components/Investor/layout';
import { Auth } from 'aws-amplify'
import ScrollAnimation from 'react-animate-on-scroll';
import { deriveHomePathFromSpace, isNullUndefined, getMomentDate, richTextOptions, capitalizeFirstLetter, transformMarkdownToReact  } from "../../util"
import Pdf_icon from '../../images/pdf_icon.png'
import Mic_icon from '../../images/mic_icon.png'
import Video_icon from '../../images/Video-icon.png'
import {showInvestorAuthFormPopup} from "../../actions/investor/prompts/forms"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import ReactDOMServer from 'react-dom/server';
import ReactHtmlParser from "react-html-parser"
import { getDefaultInvestorDisclaimerPopupConfigs } from '../../util/configs'
import SEO from "../../components/seo"
import { ValidateRoute } from '../../util/route-helper';
import TemplateWrapper from "../../components/TemplateWrapper"
import {showInvestorDisclaimerPopup} from "../../actions/investor/prompts/disclaimers/"

export const pageQueryUsaInvestorsOnlyMenuTemplateInvestor = graphql`
    query ($slug: String!, $spaceId: String!) {
        contentfulUsaInvestorsOnlyMenuTemplate(slug: { eq: $slug }, spaceId: {eq: $spaceId}) {
            bannerImage {
                file {
                    url
                }
            }
            spaceId
            slug
            title
            aboveTableText {
                childMarkdownRemark {
                    htmlAst
                }
            }
            parentMenu {
                usa_investors_only_menu_template {
                    parentMenu {
                        title
                        slug
                    }
                }
            }
            usa_investors_only_content_template {
                date
                title
                slug
                isPlaceholder
                pdf {
                    file {
                        url
                        fileName
                    }
                }
                video {
                    file {
                        url
                        fileName
                    }
                }
            }
        }
    }
`

const UsaInvestorsOnlyMenuTemplatePageInvestor = (props) => {
    const {bannerImage, title, spaceId, parentMenu, usa_investors_only_content_template, aboveTableText, isPlaceholder} = props.data.contentfulUsaInvestorsOnlyMenuTemplate;

    const dispatch = useDispatch()

    const homePath = (() => deriveHomePathFromSpace(spaceId))()

    const sortItemsByLatestDate = (a, b) => {
        let date1 = (getMomentDate(a?.date)).valueOf();
        let date2 = (getMomentDate(b?.date)).valueOf();
        return date2 - date1
    }

    const showExternalLinkDisclaimer = (externalUrl) => {
        let okCallback = () => window.open (externalUrl, "_blank")
        let closeCallback = () => {}
        let actionConfig = getDefaultInvestorDisclaimerPopupConfigs(okCallback, closeCallback)
        dispatch(showInvestorDisclaimerPopup(actionConfig));
    }

  return (
    <TemplateWrapper>
        <Layout>
         <SEO title={`${capitalizeFirstLetter(title)} | Investor or Media Relations`} />
          
                <div className={styles.banner} style={{background:`url(${isNullUndefined(bannerImage) ? "":bannerImage?.file?.url})`}}>
                <div className={styles.overlay}></div>
                            <div className="container">
                               <div className="row">
                                   <div className="col-12 col-sm-12 col-md-12">
                                   <h1 className="">
                                        {title}   
                                    </h1>
                                   <ol className="custom_breadcrumb">
                                   <li className="breadcrumb-item"><Link to={homePath}>Home</Link></li>
                                        {parentMenu?.usa_investors_only_menu_template && <li className="breadcrumb-item"><a href="#">
                                            {parentMenu?.usa_investors_only_menu_template[0]?.parentMenu?.title}    
                                        </a></li>}
                                        <li className="breadcrumb-item active" aria-current="page">
                                            {title}
                                        </li>
                                    </ol>
                               
                                   </div>
                                 
                                   </div>
                            </div>
                </div>
                <ScrollAnimation animateIn="fadeIn">
             <div className="container">
                 <div className="row mt-4">
                    <div className="col-12">
                        {aboveTableText?.childMarkdownRemark?.htmlAst && (
                            transformMarkdownToReact(aboveTableText?.childMarkdownRemark?.htmlAst, showExternalLinkDisclaimer)
                        )}
                    </div>
                 </div>


                <div className="row">
                <div className="col-12">
                    <div className="table-responsive mt-2" style={{maxWidth:"100vw"}}>
                <table className="table">
                    <thead className="thead-light">
                        <tr>
                            <th scope="col">Date</th>
                            <th scope="col">Title</th>
                            <th scope="col">PDF</th>
                            <th scope="col">Webcast</th>
                        </tr>
                    </thead> 
                    <tbody>
                        {usa_investors_only_content_template?.sort(sortItemsByLatestDate)?.map((item => {
                            let momentDate = getMomentDate(item?.date);
                            let pdfUrl = item?.pdf?.file?.url || null;
                            let videoUrl = item?.video?.file?.url || null;
                            let pdfName = item?.pdf?.file?.fileName || true;
                            let videoName = item?.pdf?.file?.fileName || true;

                            if(item?.isPlaceholder) return <React.Fragment />

                            return (
                                <tr key={item?.slug}>
                                    <td>
                                        {`${momentDate?.format("Do")} ${momentDate?.format("MMM")} ${momentDate?.format("YYYY")}`}
                                    </td>
                                    <td>
                                        {item?.title || "-"}
                                    </td>
                                    <td className="">
                                        {pdfUrl ? (
                                            <a target="_blank" style={{cursor: "pointer",  filter: "grayscale(0%)"}} href={pdfUrl} download={pdfName}>
                                                <img width="40"  className="m-0" src={Pdf_icon}/>
                                            </a>
                                        ) : (
                                            <a href={"#"} style={{cursor: "no-drop",  filter: "grayscale(100%)"}} >
                                                <img width="40"  className="m-0" src={Pdf_icon}/>
                                            </a>
                                        )}
                                    </td>
                                    <td className="">
                                        {videoUrl ? (
                                            <a target="_blank" style={{cursor: "pointer",  filter: "grayscale(0%)"}} href={videoUrl} download={videoName}>
                                                <img width="40"  className="m-0" src={Video_icon} />
                                            </a>
                                        ) : (
                                            <a href={"#"} style={{cursor: "no-drop",  filter: "grayscale(100%)"}} >
                                                <img width="40"  className="m-0" src={Pdf_icon}/>
                                            </a>
                                        )}
                                    </td>
                                </tr>
                            )
                        }))}
                    </tbody>
                    </table>
                    </div>
                   
                </div>
                 </div>
                </div>
             </ScrollAnimation>
             </Layout>
    </TemplateWrapper>
  );
}

export default UsaInvestorsOnlyMenuTemplatePageInvestor